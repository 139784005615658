










import SettingSubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import CatalogList from "@/components/organisms/catalog/CatalogList.vue";

export default {
  components: {
    SettingSubPageTemplate,
    CatalogList,
  },
};
