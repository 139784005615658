
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, helpers, alphaNum } from "vuelidate/lib/validators";
import catalogService from "@/service/catalogService";

const validCodeFormat = (value: string, parentVm: Vue): boolean => {
  const codeValue = helpers.ref("code", this, parentVm);
  var reg = new RegExp(/^[0-9a-zA-Z_-]*$/);
  return reg.test(codeValue);
};

@Component
export default class MixinCatalogEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //コード
      code: {
        required,
        validCodeFormat: validCodeFormat,
        isNotExistCatalogCode: async (
          value: string,
          parentVm: Vue
        ): Promise<boolean> => {
          if (!value) return true;
          const guid = helpers.ref("guid", this, parentVm);
          const code = helpers.ref("code", this, parentVm);
          const catalog = await catalogService.getCatalogByCode(code);
          if (guid && catalog && catalog.guid != guid) return false; //自身以外の場合はNG
          if (!guid && catalog) return false;
          return true;
        },
      },
      //名称
      name: { required },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * 名称の入力エラーメッセージを返します.
   */
  get nameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.name?.$dirty) return errors;
    !this.$v.item.name?.required && errors.push("名称を入力してください.");
    return errors;
  }
  /**
   * コードの入力エラーメッセージを返します.
   */
  get codeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.code?.$dirty) return errors;
    !this.$v.item.code?.required && errors.push("コードを入力してください.");
    !this.$v.item.code?.validCodeFormat && errors.push("コードは半角英数字と-_のみです.");
    !this.$v.item.code?.isNotExistCatalogCode &&
      errors.push("コードはすでに使用されています.");
    return errors;
  }
}
