









































import { Catalog } from "@/graphql/client";
import { Component, Emit, Vue } from "vue-property-decorator";
import catalogService from "@/service/catalogService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class CatalogEditDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: Catalog = catalogService.defaultCatalog;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(item: Catalog): void {
    this.item = item;

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  deleteItem(): void {
    this.isProgressing = true;
    //delete
    catalogService
      .deleteCatalog(this.item)
      .then((result) => {
        this.isProgressing = false;
        this.notifySuccess(result as Catalog);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * 処理成功
   */
  @Emit("onSuccess")
  public notifySuccess(deleted: Catalog): Catalog {
    return deleted;
  }
}
